@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary: transparent;
  --secondary: rgb(179, 177, 177);
  --mainColor: #ffc107;
  --scrollBack: #D4D4D4;
  --scrollFront: #FFFFFF;
}



.select__menu {
  background-color: #153C58 !important;
  color: white;
  width: 100% !important;
  min-width: 8rem !important;
  right: 0;
}

.select__option:hover {
  background-color: silver !important;
  opacity: 0.8;
  color: white;

}

.select__option--is-focused,
.select__option--is-selected {
  background-color: silver !important;
  color: white;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;

}

input[type="search"]:focus::-webkit-search-cancel-button {
  background-color: white;
  opacity: 1;
  pointer-events: all;
  cursor: pointer;

}

input[type="search"].Transparent::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>"
    );
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

input[type="search"].Transparent:focus::-webkit-search-cancel-button {
  background-color: black;
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 4px !important;
}

.sidebar-scroller::-webkit-scrollbar {
  width: 4px !important;
}

*::-webkit-scrollbar-track {
  background: var(--primary) !important;
  border-radius: 5px !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary) !important;
  border-radius: 14px !important;
  border: 3px solid var(--primary) !important;
}


.member-list {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollFront) var(--scrollBack);
  /* Set a height to make the element scrollable */
}

/* Custom scrollbar track */
.member-list::-webkit-scrollbar-track {
  background: var(--scrollBack);
  border-radius: 10px;
  /* Rounded corners for the scrollbar track */
}

/* Custom scrollbar thumb */
.member-list::-webkit-scrollbar-thumb {
  background-color: var(--scrollFront);
  /* width: 120px; */
  /* Width of the vertical scrollbar */
  height: 120px;
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
  border: 3px solid var(--scrollBack);
  /* Space between thumb and track */
}

/* Scrollbar width */
.member-list::-webkit-scrollbar {
  /* width: 120px; */
  /* Width of the vertical scrollbar */
  height: 120px;
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
  /* Height of the horizontal scrollbar (if needed) */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.custom-parent-class {
  z-index: 999999 !important;
}

.message-bubble {
  padding: 8px 12px;
  border-radius: 10px;
  position: relative;
  /* display: inline-flex; */
}

.message-bubble.sent {
  background-color: #008bfa;
  float: right;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.message-bubble.received {
  background-color: #eeeeee;
  float: left;
  color: #000000;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
}

.message-bubble.sent::before {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 10px 0 0;
  border-color: #008bfa transparent transparent transparent;
}

.message-bubble.received::before {
  content: "";
  position: absolute;
  top: 0;
  left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 0 10px;
  border-color: #eeeeee transparent transparent transparent;
}

.tox .tox-statusbar {
  border-top: none !important;
  padding: 0 !important;
  height: 0 !important;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

.react-select__control {
  height: 100% !important;
  overflow-y: auto;
  align-items: start !important;
}

.select__control {
  border: none !important;
}

.basic-single {
  border: 1px solid #9e9e9e !important;
  border-radius: 5px;
}

/* globals.css for AI icon */
.gradient-text {
  background: linear-gradient(to right, #FF5722, #FFC107);
  /* Adjust colors as needed */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.highlight {
  color: blue !important;
  /* Adjust to your desired highlight color */
  background-color: transparent;
}

.highlighted {
  color: blue !important;
  /* Adjust to your desired highlight color */
  background-color: transparent !important;
}

.DraftEditor-root {
  height: 100% !important;

}

.DraftEditor-editorContainer {
  height: 100% !important;
}

.public-DraftEditor-content {
  height: 100% !important;
}